import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { areIntervalsOverlapping } from 'date-fns'
import loadable from '@loadable/component'
import { Card } from './Card'

import Icon from '../svg/arrow-dropdown.svg'

const FormLink = loadable(() => import('./FormLink'))
const DateInput = loadable(() => import('../templates/Room/DateInput'))

const PageBuilderRetreats = ({ block }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const data = useStaticQuery(graphql`
    query {
      retreat: allSanityRetreat {
        nodes {
          _id
          title
          slug {
            current
          }
          dates {
            startDate(formatString: "D MMMM YYYY")
            endDate(formatString: "D MMMM YYYY")
          }
          detailsCard
          _rawDescription
          bgImg {
            asset {
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            alt
          }
          gallery {
            images {
              featured
              asset {
                _id
              }
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              alt
            }
          }
        }
      }
    }
  `)

  const search = typeof window !== 'undefined' ? window.location.search : null

  const resultsWrapper = useRef()
  const retreats = data.retreat.nodes

  const [results, setResults] = useState(0)
  const [dateRange, setDateRange] = useState()
  // const [themeFilter, showThemeFilter] = useState(false)
  // const [themeFilterContent, setThemeFilterContent] = useState(
  //   'Choose themes...'
  // )
  const [themeTagsContent, setThemeTagsContent] = useState()
  const [uniqueThemes, setUniqueThemes] = useState([])

  // const [filteredRetreats, setFilteredRetreats] = useState(data.retreat.nodes)
  const cardClasses = 'col-span-8 md:col-span-4'

  // useEffect(() => {
  //   const themeParam = new URLSearchParams(window.location.search)
  //   const theme = themeParam?.get('theme')
  //   const formattedTheme =
  //     theme &&
  //     `${theme.charAt(0).toUpperCase()}${theme.substr(1).toLowerCase()}`

  //   formattedTheme ? setUniqueThemes([formattedTheme]) : setUniqueThemes([])
  // }, [search])

  // // prepare the themes text
  // useEffect(() => {
  //   if (uniqueThemes.length > 0) {
  //     setThemeFilterContent(
  //       `${uniqueThemes.length} theme${uniqueThemes.length === 1 ? '' : 's'}`
  //     )
  //     setThemeTagsContent(`: ${uniqueThemes.join(',')}`)
  //   } else {
  //     setThemeFilterContent('Choose themes...')
  //     setThemeTagsContent()
  //   }
  // }, [uniqueThemes])

  // This gets the results from the cards left below.
  useEffect(() => {
    setResults(resultsWrapper.current.querySelectorAll('.card-item').length)
  })

  return (
    <section className="container grid grid-cols-12 px-5 mt-12 md:px-6">
      <div className="items-center justify-between col-span-12 mx-2 xl:col-span-10 xl:col-start-2 md:flex">
        <p className="mb-2 font-bold md:mb-1px ">
          {language == 'en' ? `${results} upcoming retreat
          ${results !== 1 ? 's' : ''}` : 'Urossimo ritiro'}
        </p>

        <div className="flex items-center">
          <div className="hidden mr-2 text-gray-600 md:inline text-14">
            Show:
          </div>
          <div className="relative w-40 mr-2 flex-0 sm:w-200px">
            <DateInput
              dateRange={dateRange}
              setDateRange={setDateRange}
              filterPage
              bgClass="bg-gray-100"
              showIcon
            />
          </div>
          {/* <div className="relative w-40 flex-0 sm:w-48">
            <button
              type="button"
              onClick={() => showThemeFilter(!themeFilter)}
              className="flex items-center w-full pl-2 pr-6 text-blue-500 transition duration-300 bg-gray-100 border border-blue-500 py-9px lg:mb-2 rounded-4 text-13 sm:text-sm hover:bg-blue-100 "
            >
              <p className="truncate">
                <span
                  dangerouslySetInnerHTML={{ __html: themeFilterContent }}
                />
                {themeTagsContent && (
                  <span
                    className="invisible font-bold md:visible"
                    dangerouslySetInnerHTML={{ __html: themeTagsContent }}
                  />
                )}
              </p>
              <Icon className="absolute transform top-50 right-4 lg:-mt-1 -translate-y-6/12" />
            </button>
            <motion.div
              animate={{
                opacity: themeFilter ? 1 : 0,
              }}
              className={` absolute z-10 top-0 w-full px-2 lg:mb-2 border bg-white border-blue-500 rounded-4 text-sm text-blue-500 ${
                themeFilter ? 'pointer-events-auto' : 'pointer-events-none'
              }`}
            >
              {themes.map((theme, index) => (
                <button
                  key={index}
                  type="button"
                  className={`block py-2 ${
                    uniqueThemes.includes(theme) ? `font-bold` : ``
                  }`}
                  onClick={() => {
                    uniqueThemes.includes(theme)
                      ? setUniqueThemes(uniqueThemes.filter(el => el !== theme))
                      : setUniqueThemes([...uniqueThemes, theme])
                    showThemeFilter(false)
                  }}
                >
                  {theme}
                </button>
              ))}
              <button
                type="button"
                className="w-full py-2 mt-2 text-left border-t"
                onClick={() => {
                  setUniqueThemes([])
                }}
              >
                {language == 'en' ? 'Clear All' : 'Cancella tutto'}
              </button>
              <button
                type="button"
                className="w-full py-2 text-left border-t"
                onClick={() => {
                  showThemeFilter(false)
                }}
              >
                Close
              </button>
            </motion.div>
          </div> */}
        </div>
      </div>

      <div
        className="grid grid-cols-8 col-span-12 gap-8 mx-2 mt-8 xl:col-span-10 xl:col-start-2 xl:gap-12 md:mb-20"
        ref={resultsWrapper}
      >
        {retreats
          // Filter by Date
          .filter(retreat => {
            if (!dateRange) return true

            const { endDate, startDate } = dateRange

            if (!endDate || !startDate) return true

            const rangeStartTS = Date.parse(startDate)
            const rangeEndTS = Date.parse(endDate)

            if (
              typeof startDate !== 'undefined' &&
              typeof endDate !== 'undefined'
            ) {
              let showThis = false

              retreat.dates.map(date => {
                const retreatStartTS = Date.parse(date.startDate)
                const retreatEndTS = Date.parse(date.endDate)

                const overlap = areIntervalsOverlapping(
                  { start: retreatStartTS, end: retreatEndTS },
                  { start: rangeStartTS, end: rangeEndTS }
                )

                // fix to stop show this resetting to false on multi date events.
                if (overlap) {
                  showThis = true
                }
              })

              return showThis
            }
            return true
          })
          // Filter by Theme
          .filter(retreat => {
            const retreatThemes = retreat.themes.map(({ title }) =>
              title.toLowerCase()
            )

            // default to show all if nothing is selected
            let isFiltered = uniqueThemes.length === 0

            if (uniqueThemes.length > 0) {
              uniqueThemes.map(theme => {
                if (retreatThemes.includes(theme.toLowerCase()))
                  isFiltered = true
              })
            }

            return isFiltered
          })
          .map(retreat => {
            let featureImage
            if (retreat.bgImg) {
              featureImage = retreat.bgImg
            } else {
              featureImage = retreat.gallery.images.filter(
                image => image.featured
              )[0]
            }

            const content = {
              id: retreat._id,
              title: retreat.title,
              slug: `/retreats/${retreat.slug.current}/`,
              features: retreat.detailsCard,
              featuredImage: featureImage,
              description: retreat._rawDescription,
            }

            return (
              <Card
                key={retreat._id}
                content={content}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                cardClasses={`card-item ${cardClasses}`}
              />
            )
          })}
      </div>
      {results === 0 && (
        <div className="col-span-12 mb-20 text-center xl:col-span-10 xl:col-start-2 lg:mb-24">
          <h3 className="text-lg">
            {language == 'en' ? 'No retreats fall within your selected dates or theme.' : 'Nessun ritiro rientra nelle date o nel tema selezionato'}
          </h3>
          <p>
          {language == 'en' ? 'Please make a new selection or' : 'Si prega di effettuare una nuova selezione o'}{' '}
            <FormLink
              linkLabel="ask us a question"
              form="General Enquiry"
              extraClasses="font-bold"
            />
          </p>
        </div>
      )}
    </section>
  )
}

export default PageBuilderRetreats
